<template>
  <h1 class="bg-gray-700 bg-opacity-50 border-b border-gray-500 py-2 text-2xl text-center text-white">Finished contests</h1>
  <div class="container mx-auto">
    <section v-if="pocetFinishedBattlov>0" class="flex justify-center flex-wrap lg:grid lg:grid-cols-2 lg:gap-2 xl:grid-cols-3 xl:gap-4 items-start self-start px-4">
          <template v-for="finishedBattleBox in getFinishedBattles" :key="finishedBattleBox.battleSettings.id">
            <finished-box :battleId="finishedBattleBox.battleSettings.id"></finished-box>
          </template>
      <div class="border-b-4 border-solid border-white max-w-lg mt-4 w-full bg-black bg-opacity-75 relative cursor-pointer" title="LOAD MORE" v-if="showButtonLoadBattles" @click="loadFinishedBattles()">
          <img :src="$store.getters.URLPICTURES+'/loadMore.webp'" class="block mx-auto py-24" alt="LOAD MORE">
      </div>
      <!-- <button class="block py-10 bg-green-our my-4 mx-auto ">Load more</button> -->
    </section>
    <section v-else class="mt-24 text-2xl text-center"> 
        Momentalne ziadny finished battle nieje k dispozicii
     </section>
  </div>
</template>

<script>
import FinishedBox from '@/components/Battles/FinishedBox.vue';
import Axios from 'axios'
// import {mapGetters} from 'vuex'
import functions from '@/components/functions.vue';

export default {
  mixins:[functions],
  components: { FinishedBox },
  created(){
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

    if('action' in this.$route.params && 'battleSeo' in this.$route.params){
      if(this.$route.params.action=="top-100-photos"){
        this.$store.commit('setLowerModal',{visible:true,whatToShow:'Top100Photos',data:{battleSeo:this.$route.params.battleSeo}});
      }else if(this.$route.params.action=="results-score-photo" || this.$route.params.action=="results-score-vote" || this.$route.params.action=="results-score-total"){
        this.$store.commit('setLowerModal',{visible:true,whatToShow:'ScoreBoard',data:{battleSeo:this.$route.params.battleSeo}});
      }
    }
    this.zistiFirstEverLoad();
  },
  computed:{
    getFinishedBattles(){
      return this.$store.getters.getFinishedBattles({paid:false});
    },
    getAllBattlesId(){
      return this.$store.getters.getBattleList.map((el)=>el.battleSettings.id);
    },
    getBattleCount(){
      return this.$store.getters.getBattleCount;
    },
    pocetFinishedBattlov(){
      if('finishedContest' in this.getBattleCount){
          return parseInt(this.getBattleCount.finishedContest);
      }
      return 0;
    },
    showButtonLoadBattles(){
      if('finishedContest' in this.getBattleCount){
        if(this.getBattleCount.finishedContest>this.getFinishedBattles.length){
          return true;
        }
      }
      return false;
    }
  },
  methods:{
    loadFinishedBattles(){
      this.$store.commit('setModalLoading',true);
      var udaje={'battles':JSON.stringify(this.getAllBattlesId),'type':'finished'};

      Axios.post(this.$store.getters.URLAJAX+'/handler.php?page=load-more-battles',udaje)
      .then((response)=>{
          var res=response.data;
            if(res.return){
              if('battleCount' in res){//-----------------zapiseme pocty contestov
                this.$store.commit('setBattleCount',res.battleCount);
              }
              if(res.battleList.length==0){
                this.showButtonLoadBattles=false;
              }else{
                this.$store.dispatch('addToBattles',res.battleList)
              }
            }else{
              this.$store.commit('setErrorModal',{visible:true,data:res.returnMsg});
            }
      })
      .catch((e)=> {
          this.$store.commit('setErrorModal',{visible:true,data:"#158 Network error"+e+""});
      })
      .finally(()=>{
          this.$store.commit('setModalLoading',false);
      }); 
    },
    zistiFirstEverLoad(){
      if(!localStorage.getItem('CFfinishedContests')){
        //--------ak otvara okno s tym ze sa otvara este top100 alebo nejake konkretne vysledky nespravi nic
        if(!this.$route.params.action){
          this.$store.commit('setUpperModal',{visible:true,whatToShow:'FirstEverLoadFinishedContests',data:{}});
          localStorage.setItem('CFfinishedContests', JSON.stringify(true));
        }
      }else{
        this.$store.commit('setFirstEverLoad',{finishedContests:true})
      }
    }
  },
  watch:{
    $route(hodnota){
      if('action' in hodnota.params && 'battleSeo' in hodnota.params){
        if(hodnota.params.action=="top-100-photos"){
          this.$store.commit('setLowerModal',{visible:true,whatToShow:'Top100Photos',data:{battleSeo:hodnota.params.battleSeo}});
        }else if(hodnota.params.action=="results-score-photo" || hodnota.params.action=="results-score-vote" || hodnota.params.action=="results-score-total"){
          this.$store.commit('setLowerModal',{visible:true,whatToShow:'ScoreBoard',data:{battleSeo:hodnota.params.battleSeo}});
        }
      }
    }
  }
}
</script>