<template>
  <div class="border-b-4 border-l border-r border-solid border-t border-gray-400 max-w-lg mt-8 shadow-glowWhite shadow-md w-full flex flex-col">
      <figure class="w-full block relative">
        <img class="block w-full h-48 object-cover object-center" :src="`${$store.getters.URLSITE}/photos/battles/${getBattle.battleSettings.image}_m.jpg`" :alt="getBattle.battleSettings.name">
        <figcaption class="absolute flex font-bold inset-0 items-center justify-center p-4 py-2 text-3xl text-center text-shadow1px1">{{ getBattle.battleSettings.name }}</figcaption>

      </figure>
      <div class="flex items-stretch justify-center relative select-none">
        <div class="flex-grow mr-2 pl-1 pr-0 py-2 text-shadow1px bg-green-our boxTimeRemainClipStart bg-opacity-30">UPLOAD</div>
        <div class="-ml-3 flex-grow leading-6 mr-2 pl-3 pr-0 py-2 boxTimeRemainBlue bg-blue-our boxTimeRemainClipMiddle bg-opacity-30">VOTE</div>
        <div class="-ml-3 flex-grow leading-6 pr-4 py-2 pl-3 boxTimeRemainPink boxTimeRemainClipEnd bg-pink-700">RESULTS</div>
      </div>

    <div class="bg-black bg-opacity-75 flex-grow" v-if="getBattleResults">
      <div class="flex flex-wrap items-center justify-around my-2" v-if="getTopPhotos.length>0">
        <finished-box-top-photos v-for="fotka in getTopPhotos" :key="fotka.id" :fotkaId="fotka.id" :battleId="getBattle.battleSettings.id"></finished-box-top-photos>
          <img class="block w-20 h-20 cursor-pointer" :src="`${$store.getters.URLSITE}/pictures/top100button.svg`" alt="TOP 100" @click="top100photos">
      </div>

      <div class="flex justify-start my-5 flex-wrap" v-if="userParticipate && userBattleResults.photosUploaded.length>0">
        <finished-box-user-photos v-for="photoUploaded in userBattleResults.photosUploaded" :key="photoUploaded.id" :fotka="photoUploaded" :battleId="getBattle.battleSettings.id"></finished-box-user-photos>
      </div>
      
      <div class="flex justify-around my-5" v-if="userParticipate">
        <div class="text-green-our text-center text-xl cursor-pointer" v-if="userBattleResults.placePhoto>0" @click="linkScorePhoto()">
          <div class="">PHOTO</div>
          <!-- <figure class="w-20 h-20">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestPhotos.svg`" alt="BEST PHOTOS">
            <figcaption class="text-white">#{{userBattleResults.placePhoto}}</figcaption>
          </figure> -->
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestPhotos.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#{{userBattleResults.placePhoto}}</figcaption>
          </figure>
          <!-- <div class="bg-green-our text-white text-xl rounded-full w-20 h-20 border-4 border-dashed border-green-600 py-5">#{{userBattleResults.placePhoto}}</div> -->
        </div>
        <div class="text-blue-our text-center text-xl cursor-pointer" v-if="userBattleResults.placeVote>0" @click="linkScoreVote()">
          <div class="">VOTE</div>
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestVotes.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#{{userBattleResults.placeVote}}</figcaption>
          </figure>
          <!-- <div class="bg-blue-our text-white text-xl rounded-full w-20 h-20 border-4 border-dashed border-blue-600 py-5">#{{userBattleResults.placeVote}}</div> -->
        </div>
        <div class="text-orange-our text-center text-xl cursor-pointer" v-if="userBattleResults.placeTotal>0" @click="linkScoreTotal()">
          <div class="">TOTAL</div>
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestTotal.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#{{userBattleResults.placeTotal}}</figcaption>
          </figure>
          <!-- <div class="bg-orange-our text-white text-xl rounded-full w-20 h-20 border-4 border-dashed border-yellow-300 py-5">#{{userBattleResults.placeTotal}}</div> -->
        </div>
      </div>
      <div class="flex justify-around my-5" v-if="!userParticipate">
        <div class="text-green-our text-center text-xl cursor-pointer" @click="linkScorePhoto()">
          <div class="">PHOTO</div>
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestPhotos.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#RES</figcaption>
          </figure>
        </div>
        <div class="text-blue-our text-center text-xl cursor-pointer" @click="linkScoreVote()">
          <div class="">VOTE</div>
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestVotes.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#RES</figcaption>
          </figure>
        </div>
        <div class="text-orange-our text-center text-xl cursor-pointer" @click="linkScoreTotal()">
          <div class="">TOTAL</div>
          <figure class="w-20 h-20 relative">
            <img :src="`${$store.getters.URLSITE}/pictures/buttonBestTotal.svg`" alt="BEST PHOTOS">
            <figcaption class="-translate-y-1/2 absolute left-0 right-0 text-center text-white top-1/2 transform">#RES</figcaption>
          </figure>
        </div>
      </div>



      <div v-if="userBattleResults.achievements.length>0" class="m-3 flex justify-start">
        <template v-for="(achiev,index) in userBattleResults.achievements" :key="index+1">
          <img :src="`${getAchievement(achiev).icon}`" @click="zobrazModal(getAchievement(achiev))" class="w-10 h-10 cursor-pointer" :alt="`${getAchievement(achiev).description}`">
        </template>
      </div>
    
      <div class="bg-gray-500 bg-opacity-60 bg-white flex justify-between p-2">
        <span class="text-sm cursor-pointer text-center sm:text-left" @click="showBattleRules()">BATTLE RULES</span>
        <span class="text-sm cursor-pointer text-center sm:text-left" @click="showGeneralRules()">GENERAL RULES</span>
      </div>
    </div>
    <div class="bg-black bg-opacity-75" v-else>
      <!-- <div class="text-2xl text-white text-center p-5">Working on results ...</div>
      <img :src="$store.getters.URLPICTURES+'/'+this.getRandImage" class="block h-32 mb-5 mx-auto opacity-80 w-32" alt="Working on results ..."> -->
      <img :src="$store.getters.URLPICTURES+'/working_on_results.png'" class="block mx-auto my-20 w-3/4" alt="Working on results ...">
    
      <div class="bg-gray-500 bg-opacity-60 bg-white flex justify-between p-2">
        <span class="text-sm cursor-pointer text-center sm:text-left" @click="showBattleRules()">BATTLE RULES</span>
        <span class="text-sm cursor-pointer text-center sm:text-left" @click="showGeneralRules()">GENERAL RULES</span>
      </div>
    
    </div>

  </div>
</template>

<script>
import FinishedBoxUserPhotos from "./FinishedBoxUserPhotos.vue";
import FinishedBoxTopPhotos from "./FinishedBoxTopPhotos.vue";
import functions from '../functions.vue';
export default {
    mixins: [functions],
    components:{FinishedBoxTopPhotos,FinishedBoxUserPhotos},
    props: ["battleId"],
    computed:{
      getBattle(){
        return this.$store.getters.getBattle(this.battleId);
      },
      getBattleResults(){
        return this.getBattle.finishedBattle.battleResults;
      },
      getTopPhotos(){
        return this.getBattle.finishedBattle.topPhotos;
      },
      // getRandImage(){
      //   return `working_on_results${Math.round(Math.random() * (4 - 1) + 1)}.svg`;
      // },
      userParticipate(){
        return this.getBattle.finishedBattle.userResult.participate;
      },
      userBattleResults(){
        return this.getBattle.finishedBattle.userResult;
      },
      getUrl(){
        if(this.getBattle.paidServices.paidBattle){
          return "exclusive-contests";
        }else{
          return "finished-contests";
        }
      }
    },
    methods:{
      getAchievement(id){
        return this.$store.getters.getAchievement(id);
      },
      zobrazModal(achievObj){
        this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInAchieve',data:{achievObj}}); 
      },
      top100photos(){
        this.$router.push({path:'/'+this.getUrl+'/top-100-photos/'+this.getBattle.battleSettings.nameSeo});
      },
      linkScorePhoto(){
        this.$router.push({path:'/'+this.getUrl+'/results-score-photo/'+this.getBattle.battleSettings.nameSeo});
      },
      linkScoreVote(){
        this.$router.push({path:'/'+this.getUrl+'/results-score-vote/'+this.getBattle.battleSettings.nameSeo});
      },
      linkScoreTotal(){
        this.$router.push({path:'/'+this.getUrl+'/results-score-total/'+this.getBattle.battleSettings.nameSeo});
      },
      showBattleRules(){
        this.$store.commit('setUpperModal',{visible:true,whatToShow:'BattleRules',data:{rules:this.getBattle.battleRules,battleImg:this.getBattle.battleSettings.image,battleName:this.getBattle.battleSettings.name}});
      },
      showGeneralRules(){
        this.$store.commit('setUpperModal',{visible:true,whatToShow:'GeneralRules',data:{}});
      },
    },
}
</script>
