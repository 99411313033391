<template>
    <img :src="photoUrl(zobrazFotografiu)" @error="zobrazFotografiu=false" class="bg-black object-contain w-24 h-24 cursor-pointer" :alt="battleId+' '+fotkaId" @click="modalZoomInImage()">
</template>

<script>
import functions from '../functions.vue';
export default {
    mixins: [functions],
    data(){
        return{
            zobrazFotografiu:true
        }
    },
    props:['battleId','fotkaId'],
    computed:{
        getFinishedBattleTopPhoto(){
            return this.$store.getters.getFinishedBattleTopPhoto({battleId:this.battleId,fotkaId:this.fotkaId});
        },
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
    },
    methods:{
        photoUrl(zobrazFotografiu){
            if(zobrazFotografiu==false){
                return this.$store.getters.URLPICTURES+"/noImage.svg";
            }else{
                return this.getFinishedBattleTopPhoto.photoPath+"_t.jpg";
            }
        },
    //     modalZoomInImage(){
    //         // console.log(this.getFinishedBattleTopPhoto,this.battleId);
    //         this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImageFinishedBattle',data:{fotka:this.getFinishedBattleTopPhoto,battleId:this.battleId}}); 
    //    }
        modalZoomInImage(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{
                photoPath:this.getFinishedBattleTopPhoto.photoPath,
                photoFileName:this.getFinishedBattleTopPhoto.photoFileName,
                exif:this.getFinishedBattleTopPhoto.exif,
                size:this.getFinishedBattleTopPhoto.size,
                isAdult:this.getFinishedBattleTopPhoto.isAdult,
                idPhoto:this.getFinishedBattleTopPhoto.idPhoto,
                place:this.getFinishedBattleTopPhoto.place,
                user:this.getFinishedBattleTopPhoto.user,
                achievements:this.getFinishedBattleTopPhoto.achievements,
                battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
        },
    }
}
</script>