<template>
    <figure class="bg-black h-14 w-14 flex items-center m-1 relative">
        <img :src="photoUrl(zobrazFotografiu)" @error="zobrazFotografiu=false" class="w-full h-full object-cover cursor-pointer relative" @click="modalZoomInImage()">
        <figcaption v-if="twoBestPhotos" class="absolute -top-4 left-0 right-0 text-center text-xs text-green-our">SCORED</figcaption>
    </figure>
</template>

<script>
import functions from '../functions.vue';
export default {
    mixins: [functions],
    data(){
        return{
            zobrazFotografiu:true
        }
    },
    props:['fotka','battleId'],
    computed:{
        getBattle(){
            return this.$store.getters.getBattle(this.battleId);
        },
        twoBestPhotos(){
            if(this.fotka.twoBestPhoto){
                return true;
            }
            return false;
        },
    },
    methods:{
        photoUrl(zobrazFotografiu){
            if(zobrazFotografiu==false){
                return this.$store.getters.URLPICTURES+"/noImage.svg";
            }else{
                return this.fotka.photoPath+"_t.jpg";
            }
        },
        modalZoomInImage(){
            this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImage',data:{
                photoPath:this.fotka.photoPath,
                photoFileName:this.fotka.photoFileName,
                exif:this.fotka.exif,
                size:this.fotka.size,
                isAdult:this.fotka.isAdult,
                idPhoto:this.fotka.id,
                place:this.fotka.place,
                achievements:this.fotka.achievements,
                userTwoBestPhoto:this.fotka.twoBestPhoto,
                battleName:this.getBattle.battleSettings.name,
                battleSeo:this.getBattle.battleSettings.nameSeo
            }}); 
            // this.$store.commit('setUpperModal',{visible:true,whatToShow:'ZoomInImageFinishedBattle',data:{fotka:this.fotka,battleId:this.battleUdaje.id}}); 
       }  
    }
}
</script>